<template>

  <b-card>
    <!-- <div class="row">
      <div class="col-12">
        <h3><strong>Carousel</strong></h3>
      </div>
    </div> -->
    <div class="row">
      <draggable
            v-model="carousel.content"
            class="cursor-move full-width"
          >
      <div
        v-for="(c,index) in carousel.content"
        :key="index"
        class="col-12"
      >
        <div class="row mt-4">
          <div :class="c.image?`col-md-8`:`col-12`">
            <div class="row">
              <!-- <div class="col-12">
                <hwa-input
                  v-model="c.position"
                  label="Position"
                  type="number"
                />
              </div> -->
              <div class="col-12">
                <hwa-input
                  v-model="c.title"
                  label="Title"
                />
              </div>
              <div class="col-12 mt-2">
                <label for="">Description</label>
              </div>
              <div class="col-12">
                <hwa-text-area
                  v-model="c.description"
                  placeholder="Description"
                />
              </div>
              <div class="col-12">
                <hwa-file-button
                  @onFileUpload="(file)=>{handleFileUpload(file,index)}"
                />
                <b-button
                  variant="danger"
                  class="float-right"
                  @click="removeCarouselItem(index)"
                > <feather-icon icon="XIcon" /></b-button>
              </div>
            </div>

          </div>
          <div
            v-if="c.image"
            class="col-md-4"
            style="display:flex;justify-content:center;align-items:center;"
          >
            <b-avatar
              v-if="c.image"
              :src="c.temp_img||getImage(c.image)"
              size="200"
            />
          </div>
        </div>
      </div>
      </draggable>
    </div>

    <div class="mt-3 d-flex justify-content-between">
      <b-button
        @click="addCarousel"
      >
        <feather-icon icon="PlusIcon" />
      </b-button>
      <div class="">
        <hwa-button
          :loading="loading"
          class=""
          label="Update"
          @onClick="updateCarousel()"
        />
      </div>
    </div>

  </b-card>
</template>

<script>
// eslint-disable-next-line
  import 'quill/dist/quill.core.css'
// eslint-disable-next-line
  import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
  import 'quill/dist/quill.bubble.css'

import HwaInput from '@/layouts/components/HwaInput.vue'
import { BCard, BAvatar, BButton } from 'bootstrap-vue'
import showToast from '@/mixins/showToast'
import HwaButton from '@/layouts/components/HwaButton.vue'
import HwaTextArea from '@/layouts/components/HwaTextArea.vue'
import HwaFileButton from '@/layouts/components/HwaFileButton.vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
export default {
  name: 'CarouselContent',
  components: {
    HwaFileButton,
    HwaTextArea,
    HwaButton,
    HwaInput,
    BCard,
    BAvatar,
    BButton,
    draggable
  },
  directives: {
    Ripple,
  },
  mixins: [showToast],
  data: () => ({
    carousel: {
      id: null,
      content: [],
    },
    loading: false,
    image: null,
  }),
  computed: {
    carouselContent() {
      return this.$store.getters['auth/carouselContent']
    },
  },
  watch: {
    carouselContent(val) {
      this.setCarousel(val)
    },
  },
  created() {
    this.setCarousel(this.carouselContent)
    this.getCarouselContent()
  },
  methods: {
    setCarousel(val) {
      if (val.content.length < 1) {
        if (this.carousel.content.length < 1) {
          this.addCarousel()
        }
      } else {
        this.carousel = val
      }
    },
    addCarousel() {
      this.carousel.content.push({
        id: '',
        title: '',
        position: null,
        description: '',
        image: null,
        temp_img: null,
      })
    },
    removeCarouselItem(index) {
      this.carousel.content.splice(index, 1)
    },
    getImage(image) {
      return `${process.env.VUE_APP_STORAGE_PATH_MORTGAGE}/${image}`
    },
    handleFileUpload(file, index) {
      this.carousel.content[index].image = file.file
      this.carousel.content[index].temp_img = file.dataUrl
    },
    updateCarousel() {
      this.loading = true
      const fd = new FormData()
      for (let i = 0; i < this.carousel.content.length; i++) {
        fd.append('title[]', this.carousel.content[i].title)
        fd.append('position[]', i)
        fd.append('description[]', this.carousel.content[i].description)
        if (typeof this.carousel.content[i].image === 'string') {
          fd.append('image_path[]', this.carousel.content[i].image)
        } else {
          fd.append('image[]', this.carousel.content[i].image)
        }
      }

      // if (JSON.stringify(fd) === '{}') {
      //   console.log(fd)
      //   this.loading = false
      // } else {
      //   console.log('file', fd)

      // }

      this.$http.post(`api/carousels/${this.carousel.id}`, fd)
        .then(res => {
          this.showSuccess('Updated Successfully')
          this.loading = false
        }).catch(err => {
          this.loading = false
          this.showError('Failed to update')
        })
    },
    getCarouselContent() {
      this.$http.get('api/carousels')
        .then(res => {
          this.$store.commit('auth/setCarouselContent', res.data[0])
          this.loaded = true
        })
    },
  },
}
</script>
